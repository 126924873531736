import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { useRef } from "react";
import { getOGimage } from "../utils/getUrl";

export default function Custom404(props) {
  const router = useRouter();

  const isFirstRender = useRef(true);

  const url = process.env.NEXT_PUBLIC_VERCEL_URL
    ? process.env.NEXT_PUBLIC_VERCEL_ENV === "production"
      ? "https://" + "ula.africa"
      : "https://" + process.env.NEXT_PUBLIC_VERCEL_URL
    : "http://localhost:3000";
  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta charSet="utf-8" />
        <title>404 - Ula</title>
        <meta name="description" content="404 - Ula" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="og:title" content="404 - Ula" />
        <meta name="og:description" content="404 - Ula" />
        <meta name="og:image" content={`${getOGimage()}`} />
      </Head>

      <div className=" flex h-[80svh] flex-col items-center justify-center px-5 lg:h-[75vh] lg:px-32">
        <h1 className=" mb-4 text-center text-3xl font-bold lg:text-4xl">
          We can&apos;t find the page you&apos;re looking for
        </h1>
        <span></span>
        <Link href="/" prefetch={false} passHref>
          <a className=" cursor-pointer  font-bold text-ula-primary">
            Take me back home
          </a>
        </Link>
      </div>
    </>
  );
}
